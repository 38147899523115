import axios from 'axios'
import store from '@/store'

export default function(baseURL) {
	const http = axios.create({ baseURL });

	let axiosPeticiones = 0;

	http.interceptors.request.use(config => {
		if (store.state.auth && store.state.auth.token) {
			let token = store.state.auth.token;
			config.headers.Authorization = 'Bearer '+token;
		}

		let showSpinner = true;

		if (typeof(config.spinner) != 'undefined')
			showSpinner = config.spinner;

		if (showSpinner) {
			if (axiosPeticiones == 0 && document.querySelectorAll('.loading').length > 0) {
				document.querySelectorAll('.loading')[0].style.display = 'block';
			}

			axiosPeticiones++;
		}

		return config;
	});

	http.interceptors.response.use(response => {
		let hideSpinner = true;

		if (response.config && typeof(response.config.spinner) != 'undefined')
			hideSpinner = response.config.spinner;

		if (hideSpinner && axiosPeticiones > 0) {
			axiosPeticiones--;

			if (axiosPeticiones == 0 && document.querySelectorAll('.loading').length > 0) {
				document.querySelectorAll('.loading')[0].style.display = 'none';
			}
		}

		return response;
	}, async (err) => {
		let hideSpinner = true;

		if (err.config && typeof(err.config.spinner) != 'undefined')
			hideSpinner = err.config.spinner;

		if (hideSpinner) {
			axiosPeticiones--;

			if (axiosPeticiones == 0 && document.querySelectorAll('.loading').length > 0) {
				document.querySelectorAll('.loading')[0].style.display = 'none';
			}
		}

		if (err.response && err.response.status == 401 && err.response.data && err.response.data.error && err.response.data.error == 'Expired token') {
			if (store.state.auth && store.state.auth.refresh) {
				let token = store.state.auth.refresh;

				return axios.post(process.env.VUE_APP_API_ACCOUNT+'/auth/refresh', {token})
				.then(res => {
					if (res.data.token) {
						store.commit('refreshCredentials', res.data);

						err.config.headers.Authorization = 'Bearer '+res.data.token;

						return axios.request(err.config);
					}else {
						return Promise.reject(err);
					}
				})
			}else {
				return Promise.reject(err);
			}
		}else {
			return Promise.reject(err);
		}
	});

	return http;
};