<template>
    <div>
        <a @click="loadWhatsapp()">
            <img src="../assets/whatsapp.svg" width="40" />
        </a>
    </div>
</template>
<script>
export default {
    props: {
        phone: String,
        text: String
    },
    computed: {
        _phone() {
            return this.phone.replace(/\D/g, "")
        },
        _text() {
            return window.encodeURIComponent(this.text).replace("%5Cn", '%0a')
        }
    },
    methods: {
        loadWhatsapp() {
            var url = `https://wa.me/521${this._phone}?text=${this._text}`;
            window.open(url, '_blank');
        }
    },
}
</script>