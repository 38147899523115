<template>
  <div class="LayoutDefault">
    <Header />
    <main class="Main">
      <slot />
    </main>
    <Footer />
  </div>
</template>
<script>
import Header from "@/layout/Header";
import Footer from "@/layout/Footer";
export default {
  name: 'LayoutDefault',
  components: {
    Header,
    Footer
  }
};
</script>