<template>
  <layout-default>
    <div class="container">
      <div class="text-center my-5">
        <h3 class="text-white">AVISO DE <span class="text-prink">PRIVACIDAD</span></h3>
      </div>
      <h4 class="my-4 text-white">
        Aviso de privacidad para Brokeres e Inversionistas
      </h4>
      <p class="text-white">
        Bien para Bien, Sociedad Anónima Promotora de Inversión de Capital
        Variable, Sociedad Financiera de Objeto Múltiple, Entidad No Regulada,
        mejor conocido como Bien para Bien, con domicilio en calle Orizaba
        Número 31, colonia Roma Norte, Ciudad de México, Alcaldía Cuauhtémoc,
        C.P. 06700, en la entidad de Ciudad de México, país México, y portal de
        internet bienparabien.com, es el responsable del uso y protección de sus
        datos personales, mismo que serán tratados con base en los principios de
        licitud, lealtad, información, consentimiento, finalidad, proporcionalidad,
        calidad, responsabilidad, seguridad, y confidencialidad en términos de la
        legislación aplicable y se utilizarán para las finalidades que se detallan en el
        presente Aviso de Privacidad, al respecto le informamos lo siguiente:
      </p>
      <h4 class="my-4 text-white">
        ¿Qué datos recabamos?
      </h4>
      <p class="text-white">
        Bien para Bien recabará datos personales, como son:
        1. Datos de identificación: nombre, edad, sexo, estado civil, fecha de
        nacimiento, nacionalidad, RFC, CURP; entre otros.
        2. Datos de contacto: domicilio, teléfonos, correo electrónico;
        referencias personales, datos académicos o profesionales; entre
        otros.
        3. Datos biométricos: imagen del rostro, video, voz, audio del titular;
        entre otros.
        4. Datos patrimoniales y/o financieros: estados de cuenta,
        declaraciones anuales, comprobantes fiscales, datos patrimoniales,
        comprobante de ingresos, escrituras públicas, documentación que
        acredite la titularidad sobre bienes muebles y/o inmuebles, facturas,
        comportamiento crediticio, datos específicos de los inmuebles que se
        dejen o se pretendan dejar en garantía, entre otros.
        Para lo cual usted nos otorga consentimiento expreso para el
        tratamiento de los datos personales recabados, pudiendo manifestar
        su negativa para el tratamiento de sus datos personales conforme a
        los términos establecidos en el presente Aviso de Privacidad.
        ¿Para qué fines utilizaremos sus datos personales?
        Los datos recabados, tendrán como finalidad:
        • Corroborar la veracidad de los datos proporcionados a Bien para
        Bien.
        • Identificar a la persona otorgante de los datos a través de medios
        físicos y digitales, como toma de muestras y almacenamiento de
        datos biométricos para su ulterior consulta y comparación.
        • Verificar la identidad del usuario, así como de sus referencias
        proporcionadas.
        • Integrar los datos al expediente, base de datos y sistemas que se
        requieran para llevar a cabo las operaciones y lo servicios de venta de
        derechos de cobro y fideicomisarios de la cartera vencida de la que
        sea titular Bien para Bien.
        • Para mantener la relación jurídica que se genere al contratar un
        servicio u operación con Bien para Bien.
        • Realizar investigaciones y acciones que se consideren necesarias, a
        efecto de comprobar a través de terceros, dependencia o autoridad,
        la veracidad de los datos proporcionados.
        • Consultar y realizar investigaciones ante sociedades de información
        crediticia que estime necesarias a fin de analizar la viabilidad de que el
        Titular sea sujeto de crédito.
        Los datos tratados por nosotros podrán ser utilizados en cualquier momento
        sin necesidad de nuevo aviso y otorgamiento de consentimiento por parte
        de usted.
        Los datos personales serán proporcionados cuando usted:
        • Se registre en el Portal de Bien para Bien para ofrecer los
        derechos de cobro y fideicomisarios de la cartera en
        incumplimiento de pago de la que sea titular Bien para Bien y/o
        algún tercero con el que tenga alguna alianza.
        • Celebre un contrato de comisión mercantil, cesión onerosa de
        derechos, carta de intención y/o cualquier documento que le
        indiquen los ejecutivos de Bien para Bien.
        • Solicite información adicional a través de nuestro sitio web y/o
        canales físicos.
        • Los proporcione vía telefónica al contactar al equipo de soporte
        y/o atención a clientes de Bien para Bien.
        • Realice actualización de sus datos personales, a través de
        cualquiera de las herramientas y medios que Bien para Bien
        ponga a su alcance.
      </p>
      <h4 class="my-4 text-white">
        ¿Para qué otros fines podemos llegar a ocupar sus datos?
      </h4>
      <p class="text-white">
        • Enviar los datos de contacto a nuestras áreas internas de cumplimiento,
        para la validación en listas negras y conocer que no existen limitaciones
        para contratar con el Broker y/o el Inversionista.
        • Para mercadotecnia, publicidad y prospección comercial que estime
        conveniente BPB.
        • Ofrecer, en su caso, otros productos financieros propios o de cualquiera
        de nuestros proveedores, aliados y/o afiliados.
        • Asimismo utilizaremos los datos para realizar investigaciones de
        mercado, revisar la actividad a través de nuestros medios electrónicos, y
        seguimiento de calidad de los mismos.
        • Para compartir sus datos personales con otras instituciones financieras,
        empresas (sin importar el giro) que tengan o puedan a llegar a tener una
        alianza estratégica o comercial, a fin de evaluar su perfil y poder ofrecer
        nuevos productos y/o servicios o en su caso realizar gestiones de
        cobranza o aquellas que sean inherentes al crédito que tiene celebrado
        con Bien para Bien.
        • Para realizar cualquier tipo de gestión a través de los empleados de Bien
        para Bien, cuando se trate de actividades relacionadas con la solicitud
        de crédito realizada, solicitud de información, cuando se tenga un
        crédito autorizado, otorgado o mientras mantenga cualquier tipo de
        relación o comunicación con Bien para Bien y hasta en tanto no sea
        limitado el uso de sus datos personales, salvo las excepciones señaladas
        en el presente Aviso de Privacidad.
        El titular podrá manifestar su negativa para el tratamiento de sus datos
        personales conforme a los términos establecidos en el presente Aviso de
        Privacidad.
      </p>
      <p class="text-white">
        ¿Cómo puede acceder, rectificar o cancelar sus datos personales, u
        oponerse a su uso?
        Usted tiene derecho a conocer qué datos personales tenemos de usted, para
        qué los utilizamos y las condiciones del uso que les damos ( Acceso).
        Asimismo, es su derecho solicitar la corrección de su información personal
        en caso de que esté desactualizada, sea inexacta o incompleta
        (Rectificación); que la eliminemos de nuestros registros o bases de datos
        cuando considere que la misma no está siendo utilizada adecuadamente
        (Cancelación); así como oponerse al uso de sus datos personales para fines
        específicos (Oposición), salvo las excepciones expresamente señaladas en
        la ley de la materia. Estos derechos se conocen como derechos ARCO.
        Para el ejercicio de cualquiera de los derechos ARCO, usted deberá presentar
        la solicitud respectiva a través del siguiente medio:
      </p>
      <p class="text-white">
        Presentando la solicitud en escrito libre firmada en el domicilio de Bien para
        Bien, con relación al procedimiento y requisitos para el ejercicio de sus
        derechos ARCO, le informamos lo siguiente:
        a) ¿A través de qué medios pueden acreditar su identidad el titular y, en su
        caso, su representante, así como la personalidad de este último?
        Identificación oficial vigente (Credencial de elector vigente, Pasaporte y/o
        cédula profesional)
        b) ¿Qué información y/o documentación deberá contener la solicitud? Los
        que se describen en el Artículo 29 de la Ley Federal de Protección de Datos
        Personales en Posesión de los Particulares
        c) ¿En cuántos días le daremos respuesta a su solicitud? 15 días hábiles
        d) ¿Por qué medio le comunicaremos la respuesta a su solicitud? Correo
        electrónico
        e) ¿En qué medios se pueden reproducir los datos personales que, en su
        caso, solicite? Documentos electrónicos
        g) Para mayor información sobre el procedimiento, ponemos a disposición
        los siguientes medios: Llamando a los teléfonos: +52 (55) 46 43 43 92 y/o +52
        (55) 79 21 20 75
        Los datos de contacto de la persona o departamento de datos personales,
        que está a cargo de dar trámite a las solicitudes de derechos ARCO, son los
        siguientes:
        a) Nombre de la persona o departamento de datos personales: Yadira
        Beatriz Azuceno Rojas
        b) Domicilio: calle Orizaba 31, colonia Roma Norte, Ciudad de México,
        alcaldía Cuauhtémoc, C.P. 06700, Ciudad de México,México.
        c) Correo electrónico: yazuceno@bienparabien.com
        ¿En qué casos se realizará la transferencia de sus datos personales?
        Bien para Bien realizará la transferencia de sus datos personales a sus
        empleados, a sus proveedores y/o principales aliados que participen en la
        labor de venta y desplazamiento de cartera vencida de Bien para Bien y sus
        aliadas. En caso de que desee revocar su consentimiento para la
        transferencia de sus datos, deberá presentar una solicitud conforme a los
        términos establecidos en el presente aviso de privacidad.
        Bien para Bien transferirá su datos personales cuando:
        • Esté prevista en una Ley o Tratado Internacional.
        • Cuando sea requerido por autoridad administrativa y/o judicial
        competente.
        • Sea necesario por virtud de un contrato celebrado o por celebrarse
        por algún proveedor o afiliado, el titular y Bien para Bien.
        • Sea preciso para el mantenimiento o cumplimiento de una relación
        jurídica entre el Titular y Bien para Bien
        • Sea con base en los demás supuestos del artículo 37 de la Ley Federal
        de Protección de Datos Personales en Posesión de los Particulares.
        Revocación del consentimiento para el uso de datos personales.
        Usted puede revocar el consentimiento que, en su caso, nos haya otorgado
        para el tratamiento de sus datos personales. Sin embargo, es importante
        que tenga en cuenta que no en todos los casos podremos atender su
        solicitud o concluir el uso de forma inmediata, ya que es posible que por
        alguna obligación legal requiramos seguir tratando sus datos personales.
        Asimismo, usted deberá considerar que para ciertos fines, la revocación de
        su consentimiento implicará que no le podamos seguir prestando el servicio
        que nos solicitó, o la conclusión de su relación con nosotros.
      </p>
      <h4 class="my-4 text-white">
        Procedimiento para revocar su consentimiento de datos personales
      </h4>
      <p class="text-white">
        Para revocar su consentimiento deberá presentar su solicitud a través del
        siguiente medio:
        Comunicándose al teléfono +52 (55) 46 43 43 92 y/o +52 (55) 79 21 20 75, con
        relación al procedimiento y requisitos para la revocación de su
        consentimiento, le informamos lo siguiente:
        a) ¿A través de qué medios pueden acreditar su identidad el titular y, en su
        caso, su representante, así como la personalidad de este último?
        Identificación oficial vigente (Credencial de elector vigente, Pasaporte y/o
        cédula profesional).
        b) ¿Qué información y/o documentación deberá contener la solicitud? Los
        que indica el Artículo 29 de la Ley Federal de Protección de Datos Personales
        en Posesión de los Particulares.
        c) ¿En cuántos días le daremos respuesta a su solicitud? 15 días hábiles
        d) ¿Por qué medio le comunicaremos la respuesta a su solicitud? Correo
        electrónico
        e) Para mayor información sobre el procedimiento, ponemos a disposición
        los siguientes medios: Comunicándose al teléfono +52 (55) 46 43 43 92 y/o
        +52 (55) 79 21 20 75
        ¿Cómo puede limitar el uso o divulgación de su información personal?
        Con objeto de que usted pueda limitar el uso y divulgación de su información
        personal, le ofrecemos los siguientes medios:
        Comunicándose al teléfono +52 (55) 46 43 43 92 y/o +52 (55) 79 21 20 75
        Asimismo, usted se podrá inscribir a los siguientes registros, en caso de que
        no desee obtener publicidad de nuestra parte:
        Registro Público de Usuarios, para mayor información consulte el portal de
        internet de la CONDUSEF
      </p>
      <p class="text-white">
        ¿Cómo puede conocer los cambios en este aviso de privacidad?
        El presente aviso de privacidad puede sufrir modificaciones, cambios o
        actualizaciones derivadas de nuevos requerimientos legales; de
        necesidades propias de Bien para Bien, por los productos o servicios que
        ofrecemos; de nuestras prácticas de privacidad; de cambios en nuestro
        modelo de negocio, o por otras causas.
        Nos comprometemos a mantenerlo informado sobre los cambios que pueda
        sufrir el presente aviso de privacidad, a través de nuestra página web
        bienparabien.com
      </p>
      <b class="text-white">
        Última actualización: 30/05/2023
      </b>
    </div>
  </layout-default>
</template>
<script>
import LayoutDefault from "@/layout/LayoutDefault";
export default {
  components: { LayoutDefault }
}
</script>