<template>
  <layout-default>
    <section class="container details">
      <div class="row">
        <div class="col-md-8">
          <div id="carouselExampleFade" class="carousel slide carousel-fade" data-ride="carousel">
            <div class="carousel-inner">
              <div v-for="(img, index) in media" :key="index" :class="'carousel-item'+(index == 0 ? ' active' : '')+' '+index">
                <img :src="img.src" class="d-block w-100" height="500px" :alt="img.caption">
              </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleFade" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Anterior</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleFade" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Siguiente</span>
            </a>
          </div>
          <div class="mt-4">
            <h5><span><img src="../assets/icons/bullet.png" alt="bullet" width="16px" class="mr-1"></span>Tipo de propiedad:</h5>
            <p>{{ tipo_garantia }}</p>
            <h5><span><img src="../assets/icons/bullet.png" alt="bullet" width="16px" class="mr-1"></span>Ubicación:</h5>
            <p>{{ direccion_compuesta }}</p>
            <section class="features">
              <h5><span><img src="../assets/icons/bullet.png" alt="bullet" width="16px" class="mr-1"></span>Características:</h5>
              <ul>
                <li>
                  <span><img src="../assets/icons/age.png" alt="bullet" width="16px" class="mr-2"></span>
                  {{ garantia.antiguedad}} año(s) de antigüedad
                </li>
                <li>
                  <span><img src="../assets/icons/bed.png" alt="bullet" width="16px" class="mr-2"></span>
                  {{ garantia.recamaras}} Recámara(s)
                </li>
                <li>
                  <span><img src="../assets/icons/bathtub.png" alt="bullet" width="16px" class="mr-2"></span>
                  {{ garantia.banos }} Baño(s)
                </li>
                <li>
                  <span><img src="../assets/icons/toilet.png" alt="bullet" width="16px" class="mr-2"></span>
                  {{ garantia.medios_banos ? garantia.medios_banos : "-" }} medio(s) baño(s)
                </li>
                <li>
                  <span><img src="../assets/icons/garage.png" alt="bullet" width="16px" class="mr-2"></span>
                  {{ garantia.estacionamientos }} Estacionamiento(s)
                </li>
                <li>
                  <span><img src="../assets/icons/cuadrado.png" alt="bullet" width="16px" class="mr-2"></span>
                  {{ garantia.superficie }}m2 de superficie
                </li>
                <li>
                  <span><img src="../assets/icons/casa.png" alt="bullet" width="16px" class="mr-2"></span>
                  {{ garantia.superficie_construida }}m2 de superficie construída
                </li>
              </ul>
            </section>
            <h5><span><img src="../assets/icons/bullet.png" alt="bullet" width="16px" class="mr-1"></span>Descripción:</h5>
            <p>{{ garantia.descripcion }}</p>
            <h5><span><img src="../assets/icons/bullet.png" alt="bullet" width="16px" class="mr-1"></span>Amenidades:</h5>
            <ul>
              <li class="text-white" v-for="amenidad in nombres_amenidades" :key="amenidad.id">{{ amenidad.amenidad }}</li>
            </ul>
            <div v-show="garantia.latitud && garantia.longitud">
              <h5><span><img src="../assets/icons/bullet.png" alt="bullet" width="16px" class="mr-1"></span>Mapa:</h5>
              <div class="mapa" id="mapa"></div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <h4><span><img src="../assets/icons/bullet.png" alt="bullet" width="20px" class="mr-1"></span>Precio:</h4>
          <h2 class="text-white">${{ $helper.moneyFormat(garantia.precio) }}</h2>
          <div class="contact">
            <div class="form-contact">
              <h3>Contáctame:</h3>
              <p class="text-small">Dejanos tus datos y un asesor se pondrá en contacto contigo.</p>
              <br>
              <ContactForm @payload="enviar_mensaje" :btnDisabled="btnDisabled" :formClear="formClear"/>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </section>

    <section v-if="listaGarantias.length > 1" class="container">
      <br />
      <h3 class="text-prink">Más inmuebles similares</h3>
      <br />
      <SimilaryHouse :garantiaid="garantia.id" @originalId="cargar_garantia" />
    </section>
  </layout-default>
</template>

<script>
import LayoutDefault from "@/layout/LayoutDefault";

import api from '@/api/portal';

import gmaps from '@/helper/gmaps';

import ContactForm from "@/components/ContactForm";
import SimilaryHouse from "@/components/SimilaryHouse";

export default {
  components: {
    SimilaryHouse,
    ContactForm,
    LayoutDefault
  },
  data() {
    return {
      garantia: {
        id: null
        ,edad: null
        ,recamaras: null
        ,banos: null
        ,medios_banos: null
        ,estacionamientos: null
        ,superficie: null
        ,superficie_construida: null
        ,descripcion: null
        ,amenidades: []
        ,imagenes: []
      }
      ,tipos_garantias:[]
      ,media: [
        // {
        //   thumb: "https://kyaooo.net/wp-content/uploads/2020/12/vallas-puertas-vallas-minecraft-768x432.jpg",
        //   src: "https://kyaooo.net/wp-content/uploads/2020/12/vallas-puertas-vallas-minecraft-768x432.jpg",
        //   caption: "<h4>Prueba 1</h4>"
        // },
      ]
      ,btnDisabled: false
      ,formClear: false
      ,listaGarantias: []
      ,originalId: null
    }
  },
  mounted: async function() {
    this.$log.info('id', this.$route.params.id);
    this.garantia = (await api.obtener_garantia(this.$route.params.id)).data;

    this.$log.info('garantia full', this.garantia);
    // this.$store.commit('setGarantia', this.garantia);

    this.listaGarantias = this.$store.getters.getListadoGarantias;

    this.tipos_garantias = this.$store.getters.getTipoGarantias;

    if (this.garantia.latitud && this.garantia.longitud) {
      this.$log.info('Cargamos google');
      this.google = await gmaps();

      this.map = new this.google.maps.Map(document.getElementById('mapa'), {
        center: {
          lat: parseFloat(this.garantia.latitud),
          lng: parseFloat(this.garantia.longitud)
        }
        ,zoom: 17
        ,disableDefaultUI: true
      })

      new this.google.maps.Marker({
        position: new this.google.maps.LatLng(parseFloat(this.garantia.latitud), parseFloat(this.garantia.longitud)),
        map: this.map,
        title: 'Garantia'
      });
    }
  },
  methods: {
    openGallery(index) {
      this.$refs.lightbox.showImage(index);
    }
    ,descargar_imagen: async function(id, garantia_id) {
      return (await api.mostrar_imagen(id, garantia_id)).data;
    }
    ,solicitar_contacto: function() {
      this.$router.push({name: 'garantia_contact', params:{id: this.garantia.id}})
    }
    ,enviar_mensaje: async function(payload) {
      try {
        if (!payload.acepto_aviso)
          return this.$helper.showMessage('Error','Para poder enviar el mensaje es requerido que aceptes el aviso de privacidad','error');

        if (!payload.email)
          delete payload.email

        if (!payload.telefono)
          delete payload.telefono

        payload.garantia_id = this.garantia.id;
        this.btnDisabled = true;
        
        (await api.mensaje(payload)).data;
        // this.$log.info('res', res);

        this.$helper.showMessage('Mensaje enviado','El mensaje se envio, a la brevedad nos comunicaremos contigo','success');
        this.formClear = true;
        this.btnDisabled = false;
        
      }catch(e) {
        this.btnDisabled = false;
        this.$log.error('err', e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
    ,cargar_garantia: async function(garantia) {
      this.garantia = (await api.obtener_garantia(garantia)).data;


      this.$log.info('garantia full', this.garantia);
      // this.$store.commit('setGarantia', this.garantia);
      
      window.scrollTo(0, 0);

      this.listaGarantias = this.$store.getters.getListadoGarantias;

      this.tipos_garantias = this.$store.getters.getTipoGarantias;

      if (this.garantia.latitud && this.garantia.longitud) {
        this.$log.info('Cargamos google');
        this.google = await gmaps();

        this.map = new this.google.maps.Map(document.getElementById('mapa'), {
          center: {
            lat: parseFloat(this.garantia.latitud),
            lng: parseFloat(this.garantia.longitud)
          }
          ,zoom: 17
          ,disableDefaultUI: true
        })

        new this.google.maps.Marker({
          position: new this.google.maps.LatLng(parseFloat(this.garantia.latitud), parseFloat(this.garantia.longitud)),
          map: this.map,
          title: 'Garantia'
        });
      }
    }
    ,formatear_amenidades(){
      this.amenidades = this.$store.getters.getAmenidades;

      let amenidades_format = [];
      this.amenidades.forEach(amenidad=>{
          if (this.garantia.amenidades.some(new_amenidad => new_amenidad.amenidad === amenidad.valor))
              amenidades_format.push({id:amenidad.id, amenidad: amenidad.nombre});
      });            
      return this.garantia.amenidades = amenidades_format;              
    }    
  },
  computed: {
    tipo_garantia: function() {
      if (this.garantia) {
        let tipo_garantia = "";
        this.tipos_garantias.forEach(tipo => {
          if(tipo.valor == this.garantia.tipo_garantia)
            tipo_garantia = tipo.nombre;
        });

        return tipo_garantia;
      }

      return null;
    }
    ,estado: function() {
      let estado = this.garantia.estado;
      this.$store.getters.getEstados.forEach(tmp => {
        if (tmp.valor == this.garantia.estado)
          estado = tmp.nombre;
      })

      return estado;
    }
    ,direccion_compuesta: function() {
      let direccion = "";

      if (this.garantia) {
        if (this.garantia.calle)
          direccion += this.garantia.calle;

        if (this.garantia.no_interior)
          direccion += " "+this.garantia.no_interior;

        if (this.garantia.no_exterior)
          direccion += " #"+this.garantia.no_exterior;

        if (this.garantia.colonia)
          direccion += ", "+this.garantia.colonia;

        if (this.garantia.delegacion_municipio)
          direccion += ", "+this.garantia.delegacion_municipio;

        if (this.garantia.estado)
          direccion += ", "+this.estado;

        if (this.garantia.cp)
          direccion += ", CP "+this.garantia.cp;
      }

      return direccion;
    }
    ,nombres_amenidades(){
      return this.formatear_amenidades();
    }    
  }
  ,watch: {
    garantia: async function() {
      if (this.garantia && this.garantia.imagenes && this.garantia.imagenes.length > 0) {
        let media = [];

        for(let i=0; i<this.garantia.imagenes.length; i++) {
          // this.garantia.imagenes[i]
          let binary = this.descargar_imagen(this.garantia.id, this.garantia.imagenes[i].id);
          binary.then(() => {
            media.push({
              // src: 'data:'+this.garantia.imagenes[i].mime+';base64,'+data
              src: process.env.VUE_APP_PORTAL_URL+'/portal/garantia/'+this.garantia.id+'/imagen/'+this.garantia.imagenes[i].id
              ,caption: this.garantia.imagenes[i].descripcion
            });
          })
        }

        this.media = media;
      }
    }
  }
}

</script>

<style lang="scss">
  .mapa {
    width: 100%;
    height: 400px;
  }
</style>