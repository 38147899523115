import Vue from "vue";
import VueRouter from "vue-router";
import Privacy from "@/views/Privacy";
import Home from "@/views/Home";
import Contact from "@/views/Contact";
import Terms from "@/views/Terms";
import ListOfProperties from "@/views/ListOfProperties";
import PropertyDetails from "@/views/PropertyDetails";

Vue.use(VueRouter);

const routes = [
  {
    path: `/`,
    name: `home`,
    component: Home
  },
  {
    path: `/contacto`,
    name: `contact`,
    component: Contact
  },
  {
    path: `/aviso-de-privacidad`,
    name: `privacy`,
    component: Privacy
  },
  {
    path: `/terminos-y-condiciones`,
    name: `terms`,
    component: Terms
  },
  {
    path: `/propiedades-en-venta`,
    name: `ListOfProperties`,
    component: ListOfProperties
  },
  {
    path: `/detalle-de-la-propiedad/:id`,
    name: `PropertyDetails`,
    component: PropertyDetails
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
