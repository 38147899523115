import Vue from "vue";

export default {
  showAxiosError: function(err, title, config) {
    Vue.prototype.$log.info('err', err.response);
    let errorMessage = typeof(err) == 'string' ? err : 'Error desconocido';

    if (err.response && err.response.data && err.response.data.message)
      errorMessage = err.response.data.message;

    if (err.response && err.response.data && err.response.data.error) {
      errorMessage += '<ul>';
      if (typeof(err.response.data.error) != 'string')
        Object.keys(err.response.data.error).forEach(key => {
          errorMessage += '<li>'+err.response.data.error[key]+'</li>';
        })
      else
        errorMessage += '<li>'+err.response.data.error+'</li>';

      errorMessage += '</ul>';
    }

    if (err.response && err.response.data && err.response.data.errors) {
      errorMessage += '<ul>';
      Object.keys(err.response.data.errors).forEach(key => {
        errorMessage += '<li>'+err.response.data.errors[key]+'</li>';
      })
      errorMessage += '</ul>';
    }

    if (typeof(config) == 'undefined')
      config = {
        group: 'notifications'
        ,type: 'error'
        ,title: title
        ,duration: 15000
      };

    if (!config.group)
      config.group = 'alert';

    if (!config.type)
      config.type = 'error';

    if (!config.duration)
      config.duration = 15000;

    config.text = errorMessage;

    Vue.prototype.$notify(config);
  }
  ,showMessage: function(titulo, mensaje, tipo, grupo, duracion) {
    let payload = null;

    if (typeof(tipo) == 'string' || typeof(tipo) == 'undefined') {
      if (!tipo)
        tipo = 'success'

      if (!grupo)
        grupo = 'notifications';

      if (!duracion)
        duracion = 10000;

      payload = {
        group: grupo
        ,type: tipo
        ,title: titulo
        ,text: mensaje
        ,duration: duracion
      };
    }else {
      payload = {
        type: tipo.tipo ? tipo.tipo : 'success'
        ,group: tipo.grupo ? tipo.grupo : 'notifications'
        ,duration: tipo.duracion ? tipo.duracion : 10000
        ,width: tipo.ancho ? tipo.ancho : 300
        ,title: titulo
        ,text: mensaje
      }
    }

    Vue.prototype.$notify(payload);
  }
  ,url_options: function(url, options) {
    if (options && options.filters)
      Vue.prototype.$log.info('options', options.filters.length);

    let full_url = url;

    if (options) {
      full_url+='?display=frontend';

      if (options.page)
        full_url+='&page='+options.page;
      if (options.order_col)
        full_url+= '&order_col='+options.order_col
      if (options.order_dir)
        full_url+= '&order_dir='+options.order_dir
      if (options.limit)
        full_url+= '&limit='+options.limit;
      if (options.num_items)
        full_url+= '&num_items='+options.num_items;
      if (options.search)
        full_url+= '&search='+encodeURI(options.search);

      if (options.filters && options.filters.length > 0) {
        let numFilter = 0;
        options.filters.forEach(function(item) {
          let filter = '&f['+numFilter+'][column]='+encodeURI(item.columna);
          filter += '&f['+numFilter+'][operator]='+encodeURI(item.operador);
          filter += '&f['+numFilter+'][query_1]='+encodeURI(item.valor1);
          filter += item.valor2 !== null ? '&f['+numFilter+'][query_2]='+encodeURI(item.valor2) : '';

          full_url+= filter;
          numFilter++;
        });
      }
    }

    return full_url;
  }
  ,moneyFormat: function(amount, decimalCount = 2, decimal = ".", thousands = ",") {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;

      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
      Vue.prototype.$log.info('error', e);
    }
  }
}