<template>
  <layout-default>
    <div class="container contact">
      <h2 class="text-prink">Contáctanos</h2>
      <p class="text-white">Deja tu información y un asesor se comunicará contigo.</p>
      <div class="row">
        <div class="col-md-6 form-contact">
          <ContactForm @payload="enviar_mensaje" :btnDisabled="btnDisabled" :formClear="formClear"/>
        </div>
        <div class="col-6 contact__img">
          <img class="d-none d-md-block" src="../assets/icons/contacto.png" alt="Contacto">
        </div>
      </div>
    </div>
  </layout-default>
</template>

<script>
import LayoutDefault from "@/layout/LayoutDefault";
import ContactForm from "@/components/ContactForm";
import api from '@/api/portal'

export default {
  name: 'Contacto',
  components: {
    ContactForm,
    LayoutDefault
  }
  ,data: function() {
    return {
      btnDisabled: false
      ,formClear: false
    }
  }
  ,methods: {
    enviar_mensaje: async function(payload) {
      try {
        if (!payload.acepto_aviso)
          return this.$helper.showMessage('Error','Para poder enviar el mensaje es requerido que aceptes el aviso de privacidad','error');

        if (!payload.email)
          delete payload.email

        if (!payload.telefono)
          delete payload.telefono

        this.btnDisabled = true;

        let res = (await api.mensaje(payload)).data;
        this.$log.info('res', res);

        this.btnDisabled = false;

        this.$helper.showMessage('Mensaje enviado','El mensaje se envio, a la brevedad nos comunicaremos contigo','success');
        this.formClear = true;
      }catch(e) {

        this.btnDisabled = false;
        this.$log.error('err', e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
  }
}
</script>