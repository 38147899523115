<template>
  <div class="card-deck similary-house">
    <template v-for="garantia in lista_garantias">
    <div v-if="garantia.id != garantiaid" :key="garantia.id" class="card col-md-3">
      <img  :src="imagen(garantia)" class="card-img-top" alt="Casa muestra" height="200px">
      <div class="card-body text-white">
        <h5 class="card-title">${{ $helper.moneyFormat(garantia.precio) }}</h5>
        <p class="card-text text-white">
          <small class="text-white">
            {{ garantia.superficie }}m2 de terreno | {{ garantia.recamaras }} recámaras | {{ garantia.banos }} baños
          </small>
        </p>
        <p class="card-text">
          <span>{{ garantia.titulo_nombre }}</span> <br>
          <span class="text-small text-white">{{ direccion_compuesta(garantia) }}</span>
        </p>
      </div>
      <div class="d-flex justify-content-end m-3">
        <button class="btn btn-success" type="button" @click="seleccionar_garantia(garantia)">Mostrar</button>
      </div>
    </div>
    </template>
  </div>
</template>

<script type="text/javascript">
  export default {
    props: {
      garantiaid: {
        type: Number
        ,default: 0
      }
    }
    ,data: function() {
      return {
        garantias: []
        ,estados: []
      }
    }
    ,mounted: function() {
      this.garantias = this.$store.getters.getListadoGarantias;
      this.estados = this.$store.getters.getEstados;
    }
    ,methods: {
      imagen: function(garantia) {
        return process.env.VUE_APP_PORTAL_URL+'/portal/garantia/'+garantia.id+'/imagen/'+garantia.imagenes[0].id;
      }
      ,direccion_compuesta: function(garantia) {
        let direccion = garantia.calle;

        if (garantia.no_interior)
          direccion += " "+garantia.no_interior;

        if (garantia.no_exterior)
          direccion += " #"+garantia.no_exterior;

        if (garantia.colonia)
          direccion += ", "+garantia.colonia;

        if (garantia.delegacion_municipio)
          direccion += ", "+garantia.delegacion_municipio;

        if (garantia.estado)
          direccion += ", "+this.estado(garantia.estado);

        if (garantia.cp)
          direccion += ", CP "+garantia.cp;

        return direccion;
      }
      ,estado: function(estado_raw) {
        let estado = "";

        this.estados.forEach(tmp => {
          if (tmp.valor == estado_raw)
            estado = tmp.nombre;
        });

        return estado;
      }
      ,seleccionar_garantia: function(garantia) {
        this.$log.info('NuevaGarantia: ',garantia);
        this.$emit('originalId',garantia.id);
        
        this.$router.replace({name: 'PropertyDetails', params:{id: garantia.id}})
      }
    }
    ,computed: {
      lista_garantias: function() {
        let garantias = [];

        if (this.garantias && this.garantias.length > 0) {
          let inicio = this.garantias.length > 4 ? 4 : this.garantias.length;
          this.$log.info('inicio', inicio);
          for(let i=0; i<inicio; i++) {
            if (this.garantias[i]) {
              if (this.garantias[i].id != this.garantiaid)
                garantias.push(this.garantias[i]);
              else if (this.garantias.length > 1)
                inicio++;
            }
          }
        }

        return garantias;
      }
    }
  }
</script>