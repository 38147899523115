<template>
  <layout-default>
    <div class="container">
      <div class="text-center my-5">
        <h3 class="text-white">
          TÉRMINOS Y <span class="text-prink">CONDICIONES</span></h3>
      </div>

      <h4 class="my-4 text-white">OBJETO Y ACEPTACIÓN</h4>
      <p class="text-white">
        Los presentes Términos y Condiciones, tienen como propósito, describir las reglas y lineamientos generales
        a las que se obliga el Usuario que se dé de alta en el Portal, como Broker y/o Inversionista de Bien para
        Bien.
        Los Términos y Condiciones tienen un carácter obligatorio y vinculante, sin la aceptación de los mismos el
        Usuario no podrá continuar con el proceso de registro como Broker y/o Inversionista de Bien para Bien.
        El Usuario acepta expresamente que conoce el contenido íntegro de los Términos y Condiciones, razón por
        la que proporcionó su consentimiento expreso a través del Portal.</p>
      <h4 class="my-4 text-white">DEFINICIONES</h4>
      <p class="text-white">Para facilitar la interpretación de los Términos y Condiciones, todos los términos con mayúscula inicial,
        tendrán
        el significado que se les atribuye en el glosario siguiente, serán aplicables a la forma singular y plural
        dependiendo del contexto.</p>
      <h4 class="my-4 text-white">
        Los términos enlistados a continuación tendrán el siguiente significado:</h4>
      <p class="text-white">
        Bien para Bien.- Se refiere a BIEN PARA BIEN S.A.P.I. DE C.V. SOFOM E.N.R.
        Broker.- Se refiere a la persona que se dedica a promocionar y concretar la venta de las Propiedades y que
        para el pago de cualquier Comisión deberá suscribir con Bien para Bien el Contrato de Comisión Mercantil.
        Comisión.- Contraprestación a la cual el Broker tiene derecho con motivo de la promoción y cierre de Venta
        de los Derechos de Créditos Ejercidos, de acuerdo con el Contrato de Comisión Mercantil que suscriba con
        Bien para Bien.</p>
      <p class="text-white">Contrato de Comisión Mercantil.- Se refiere al Contrato celebrado entre un Broker y Bien para Bien cuya
        finalidad es definir los derechos y obligaciones de cada una de las partes por la referenciación y cierre de
        operaciones de venta de Créditos Ejercidos.</p>
      <p class="text-white">Crédito Ejercido.- Se refiere a aquellos créditos que han sido debidamente otorgados por Bien para Bien y/o
        cualquiera de las empresas con las que mantenga una alianza comercial, el cual se encuentra garantizado
        con un bien inmueble, el cual puede estar aportado a un fideicomiso de garantía, tener constituida una
        hipoteca
        o celebrado algún convenio de mediación.</p>
      <p class="text-white">
        Información Confidencial.- (i) toda y cada una de la información que el Broker conozca o pueda llegar a
        conocer o a acceder como consecuencia de su relación comercial y contractual, sea actual o potencial; (ii)
        aquella información que Bien para Bien transmita al Broker, a través de y contenido en cualquier medio como
        consecuencia de este esta relación; (iii) todo aquel material documental en imágenes que tenga o pueda tener
        valor comercial o cualquier otra utilidad en el negocio de Bien para Bien o de cualquiera de sus Empresas
        Filiales; (iv) aquella cuya revelación no autorizada pueda ocasionar detrimento a los intereses de Bien para
        Bien o de sus Empresas Filiales; (v) cualesquiera secretos industriales o de negocios, listas de clientes,
        identidades y características de clientes y proveedores, acuerdos, conocimiento de mercado e información de
        ventas, información sobre precios, planes de mercado y planes de negocios, estrategias, estimaciones a
        futuro, información financiera, presupuestos financieros, software, papeles de investigación, proyecciones,
        procedimientos, rutinas, información de negocios, procedimientos de control de calidad, procesos comerciales
        o de comercialización, innovaciones, procesos y procedimientos, mejoras, datos, know-how; y (vi) en general,
        cualquier otra información que objetivamente pueda ser considerada como confidencial por la delicadeza de
        la misma, y cualquier otra información que sea proporcionada con el carácter de confidencial por Bien para
        Bien a el Broker.
      </p>
      <p class="text-white">
        Inversionista.- Se refiere a la persona interesada en adquirir alguna de las Propiedades publicadas en el
        Portal, la cual cuenta con el recurso económico suficiente para para el precio del Inmueble.
      </p>
      <p class="text-white">
        Propiedades.- Se refiere a los inmuebles que se encuentran publicados en el Portal, las cuales garantizan los
        Créditos Ejercidos y se ofrecen para la venta de los derechos de crédito y fideicomisarios..
        Usuario.- Persona interesada en formar parte de la red de Brokeres y/o Inversionistas de Bien para Bien y
        que sigue el proceso de registro para tal efecto.
        Portal.- Se refiere al Portal de internet a través del cual el Usuario podrá registrarse para formar parte de
        la
        red de Brokeres y/o Inversionistas de Bien para Bien, así como para ingresar al perfil que le sea asignado por
        parte de Bien para Bien.
      </p>
      <p class="text-white">
        Términos y Condiciones.- Se refiere a los presentes términos y condiciones, en los que se describen las
        reglas y lineamientos generales a las que se obliga la persona que se dé de alta en El Portal, como Broker de
        Bien para Bien.
      </p>
      <p class="text-white">
        DESCRIPCIÓN DE LA RELACIÓN ENTRE EL BROKER Y BIEN PARA BIEN
        El Broker realizará labores de promoción, operación y cierre de contratos en favor de Bien para Bien, lo que
        realizará únicamente con la información, materiales y recursos que le sean proporcionados por Bien para Bien,
        con la intención de que sean vendidos los derechos de cobro y fideicomisarios de las propiedades.
        Asimismo si alguna persona está interesada en comprar de manera directa los derechos de alguna de las
        Propiedades Publicadas, deberá manifestar su interés a Bien para Bien y seguir todo el proceso de validación
        y contratación que le indiquen los ejecutivos de Bien para Bien.
        El Portal muestra las Propiedades con el objetivo de que las mismas sean comercializadas, haciendo del
        conocimiento de los Usuarios, que la venta que se realiza es de los derechos de cobro y fideicomisarios y no
        así la venta del inmueble de manera directa. Cualquier venta que se pretende realizar de manera directa en
        relación con alguno de los inmuebles publicados, se deberá validar el proceso con los ejecutivos de Bien para
        Bien.
      </p>
      <p class="text-white">
        COSTOS Y FORMAS DE PAGO
        Todos las Propiedades de Créditos Ejercidos que hayan sido colocados y vendidos por el Broker, serán
        pagados a éste con base al porcentaje que determine Bien para Bien y que esté amparado en el Contrato de
        Comisión Mercantil que hubieren celebrado las partes.
      </p>
      <h4 class="my-4 text-white">
        VIGENCIA
      </h4>
      <p class="text-white">
        La vigencia será por el tiempo que dure la Comisión, siendo facultad de Bien para Bien revocar en cualquier
        momento al Broker, bastando para ello, la notificación que realice Bien para Bien al correo electrónico de
        registro del Broker. La revocación surtirá efectos inmediatamente, desde el momento de envío de la
        notificación.
      </p>
      <h4 class="my-4 text-white">
        OBLIGACIONES DE EL BROKER
      </h4>
      <p class="text-white">
        - Actuar con diligencia y profesionalismo, poniendo su máximo esfuerzo para lograr la mayor cantidad de
        ventas.
        - No cobrar ningún tipo de comisión, servicio o cualquier contraprestación a los interesados en adquirir
        derechos.
        - No hacer mal uso de la marca, logo, nombre comercial, signos distintivos, Información Confidencial, ni
        cualquier derecho de propiedad intelectual y/o derechos de autor de los que Bien para Bien ostente la
        titularidad. En caso de contravención a lo dispuesto en el presente inciso, EL BROKER, responderá de los
        daños y perjuicios causados a Bien para Bien, con independencia de las sanciones de carácter administrativo
        y/o penal que pudieran aplicar de conformidad con la legislación de la materia.
        - No excederse de las funciones y facultades expresamente conferidas por Bien para Bien.
        - No realizar actos de competencia desleal en perjuicio de Bien para Bien.
        - No deberá recibir ningún tipo de pago, en efectivo o especie, de interesados y/o Inversionistas. Cualquier
        pago por la compra de los derechos por parte de algún Inversionista, deberá realizarlo por transferencia
        electrónica o depósito a la cuenta bancaria que ostente como titular a Bien para Bien. Cualquier
        incumplimiento
        al presente párrafo dará origine al inicio de las acciones penales correspondientes.
        - El bróker no podrá publicar las Propiedades en ningún portal de inmuebles ajenos al Portal de Bien para
        Bien, en el cual se está registrando.
        El Broker y/o Inversionista acepta expresamente que conoce las obligaciones y restricciones aplicables
        conforme a los presentes Términos y Condiciones.
        Cualquier mala práctica o incumplimiento a los presentes Términos y Condiciones, serán motivo para dar de
        baja al Broker de la red de Brokeres de Bien para Bien, con independencia de las sanciones y
        responsabilidades legales que llegaren a ser aplicables, en cuyo caso quedan a salvo los derechos de Bien
        para Bien, para ejercer las acciones legales correspondientes.
      </p>
      <h4 class="my-4 text-white">
        PROPIEDAD INTELECTUAL
      </h4>
      <p class="text-white">
        Todos los contenidos del presente contrato (incluyendo, pero no limitado a, texto, logotipos, contenido,
        fotografías, nombres comerciales, denominaciones, marcas y/o cualquier otra Información Confidencial) están
        sujetos a derechos de propiedad intelectual y derechos de autor, en su caso.
        En ningún caso se entenderá que se concede licencia alguna o se efectúa renuncia, transmisión, cesión total
        o parcial de dichos derechos ni se confiere ningún derecho, y en especial, de alteración, explotación,
        reproducción, distribución o comunicación pública sobre dicho contenido sin la previa autorización expresa de
        Bien para Bien. Queda prohibido copiar, reproducir, distribuir, publicar, transmitir, difundir, o en cualquier
        modo
        explotar cualquier parte de este servicio sin la autorización previa por escrito de Bien para Bien o de los
        titulares
        correspondientes.
      </p>
      <p class="text-white">
        La información que sea objeto de protección de propiedad industrial, será exclusivamente para fines
        informativos, y cualquier uso distinto como el lucro, reproducción, edición o modificación, será perseguido y
        sancionado conforme a las leyes de la materia.
      </p>
      <h4 class="my-4 text-white">
        CONFIDENCIALIDAD
      </h4>
      <p class="text-white">
        El Broker se obliga a:
        a) No usar el acceso a la información que le otorgue Bien para Bien, ni divulgar, ni revelar en forma alguna
        la
        Información Confidencial.
        b) Bien para Bien tendrá en todo momento la facultad y el derecho de retirar el acceso a la información que
        haya proporcionado, unilateralmente y sin necesidad de previo aviso.
      </p>
      <h4 class="my-4 text-white">
        RESPONSABILIDAD POR DAÑOS Y PERJUICIOS
      </h4>
      <p class="text-white">
        El Broker responderá por los daños y perjuicios causados a Bien para Bien, por incumplimiento a los Términos
        y Condiciones, en especial las violaciones a obligaciones de confidencialidad y propiedad intelectual.
        PROTECCIÓN DE DATOS
        El Broker y el Inversionista reconocen que Bien para Bien puso a su disposición el contenido del aviso de
        privacidad, mismo que fue aceptado por el Broker y el Inversionista.
        OTRAS
      </p>
      <p class="text-white">
        - Bien para Bien se reserva el derecho de incluir a su red de Brokeres, el número de personas que considere
        convenientes.
        - No existe una relación de subordinación entre el Broker y Bien para Bien
        - El Broker se obliga expresamente a notificar cualquier cambio en los datos proporcionados a Bien para
        Bien al momento del Registro o durante la vigencia de la relación con Bien para Bien.
        - El Broker acepta expresamente que todos los avisos, notificaciones y/o modificaciones referentes a los
        Términos y Condiciones, le sean notificados al correo electrónico con el cual se registró en el Portal de.Bien
        para Bien y /o a través de los Términos y Condiciones que estarán publicados en el Portal, siendo obligación
        del Broker y el Inversionista estarlos consultando de manera recurrente.
        - Bien para Bien se reserva la facultad de realizar las modificaciones que estime conveniente a los Términos
        y Condiciones, los cuales serán notificados al Broker en la cuenta de correo electrónico con la que se
        registró
        y/o a través del Portal en el cual se encuentren registrados. El Broker contará con tres días hábiles para
        manifestar su inconformidad dichos cambios y en su caso solicitar su baja de la red de Brokeres.
        JURISDICCIÓN Y LEY APLICABLE.- Para todo lo no previsto en el presente contrato son aplicables las
        disposiciones contenidos en el Código de Comercio, así como la legislación federal aplicable.
        Para la resolución de cualquier conflicto que pudiera surgir con motivo de la interpretación, ejecución y
        cumplimiento de lo aquí estipulado, las Partes se someten expresamente a las leyes antes referidas y a la
        jurisdicción de los tribunales de la Ciudad de México, renunciando expresamente a cualquier fuero que por
        razón de su domicilio presentes o futuros les pudiera corresponder.
      </p>
    </div>
  </layout-default>
</template>
<script>
import LayoutDefault from "@/layout/LayoutDefault";
export default {
  components: { LayoutDefault }
}
</script>