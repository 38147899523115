<template>
  <layout-default>
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <SearchFilter @filtros="obtener_filtros" />
        </div>
        <div class="list-of-propierties col-md-8">
          <Property v-for="garantia in garantias" :key="garantia.id" :garantia="garantia"/>
          <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center">
              <li v-if="options.current_page > 1" class="page-item">
                <a class="page-link" href="#" @click.prevent="actualizar_pagina(options.current_page-1)">Anterior</a>
              </li>
              <li v-else class="page-item disabled">
                <a class="page-link" href="#">Anterior</a>
              </li>
              <li v-for="pagina in total_paginas" :key="pagina" :class="'page-item'+(pagina == options.current_page ? ' active' : '')"><a class="page-link" href="#" @click.prevent="actualizar_pagina(pagina)">{{ pagina }}</a></li>
              <li v-if="options.current_page < options.last_page" class="page-item">
                <button class="page-link" tabindex="-1" aria-disabled="true" @click.prevent="actualizar_pagina(options.current_page+1)">Siguiente</button>
              </li>
              <li v-else class="page-item disabled">
                <span class="page-link" tabindex="-1">Siguiente</span>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </layout-default>
</template>

<script>
  import LayoutDefault from "@/layout/LayoutDefault";
  import SearchFilter from "@/components/SearchFilter";
  import Property from "@/components/Property";

  import api from "@/api/portal";

  export default {
    name:'ListOfProperties',
    components: {
      Property,
      SearchFilter,
      LayoutDefault,
    }
    ,data: function() {
      return {
        garantias: []
        ,options: {
          page:1,
          last_page: 1,
          paginas: 20,
          order_dir: "asc",
          order_col: "id",
          limit: 5,
          filters: []
        }
        ,pagina: 1
        ,filtros: null
      }
    }
    ,mounted: async function() {
      // this.cargar_garantias();
    }
    ,methods: {
      cargar_garantias: async function() {
        try {
          let busqueda = this.$store.getters.getBusqueda;

          this.$log.info('listado options', this.options);
          if (!busqueda.estado || !busqueda.tipo)
            this.$router.push({name: "home"});

          this.options = (await api.obtener_garantias(busqueda.tipo, busqueda.estado, {page:this.pagina,limit:5})).data;

          this.garantias = this.options.data;
          this.$log.info('listado garantias', this.garantias);


          this.$store.commit('setListadoGarantias', this.garantias);
          
          // this.options.page = res.current_page;
          // this.options.last_page = res.last_page;
          // this.options.total = parseInt(res.per_page);
        }catch(e) {
          this.$log.info("error",e);
        }
      }
      ,obtener_filtros: function(filtros) {
        this.$log.info('filtros: ', filtros);
        this.options.filters = [];

        if (filtros.ubicacion && filtros.ubicacion != "")
            this.$store.commit('setBusqueda', {estado: filtros.ubicacion, ubicacion:filtros.tipo_inmueble});

        if (filtros.tipo_inmueble && filtros.tipo_inmueble != "")
            this.$store.commit('setBusqueda', {tipo: filtros.tipo_inmueble, estado:filtros.ubicacion});

        if (filtros.precio && filtros.precio != "")
          this.options.filters.push({
            columna: 'precio'
            ,operador: 'between'
            ,valor1: (filtros.precio.indexOf('|') != -1 ? filtros.precio.substr(0,filtros.precio.indexOf('|')) : filtros.precio)
            ,valor2: (filtros.precio.indexOf('|') != -1 ? filtros.precio.substr(filtros.precio.indexOf('|')+1) : 1000)
          });

        if (filtros.numero_recamaras && filtros.numero_recamaras != "")
          this.options.filters.push({
            columna: 'recamaras'
            ,operador: 'between'
            ,valor1: (filtros.numero_recamaras.indexOf('|') != -1 ? filtros.numero_recamaras.substr(0,filtros.numero_recamaras.indexOf('|')) : filtros.numero_recamaras)
            ,valor2: (filtros.numero_recamaras.indexOf('|') != -1 ? filtros.numero_recamaras.substr(filtros.numero_recamaras.indexOf('|')+1) : 1000)
          });

        if (filtros.numero_banios && filtros.numero_banios != "")
          this.options.filters.push({
            columna: 'banos'
            ,operador: 'between'
            ,valor1: (filtros.numero_banios.indexOf('|') != -1 ? filtros.numero_banios.substr(0,filtros.numero_banios.indexOf('|')) : filtros.numero_banios)
            ,valor2: (filtros.numero_banios.indexOf('|') != -1 ? filtros.numero_banios.substr(filtros.numero_banios.indexOf('|')+1) : 1000)
          });

        if (filtros.numero_estacionamientos && filtros.numero_estacionamientos != "")
          this.options.filters.push({
            columna: 'estacionamientos'
            ,operador: 'between'
            ,valor1: (filtros.numero_estacionamientos.indexOf('|') != -1 ? filtros.numero_estacionamientos.substr(0,filtros.numero_estacionamientos.indexOf('|')) : filtros.numero_estacionamientos)
            ,valor2: (filtros.numero_estacionamientos.indexOf('|') != -1 ? filtros.numero_estacionamientos.substr(filtros.numero_estacionamientos.indexOf('|')+1) : 1000)
          });

        if (filtros.antiguedad && filtros.antiguedad != "")
          this.options.filters.push({
            columna: 'antiguedad'
            ,operador: 'between'
            ,valor1: (filtros.antiguedad.indexOf('|') != -1 ? filtros.antiguedad.substr(0,filtros.antiguedad.indexOf('|')) : filtros.antiguedad)
            ,valor2: (filtros.antiguedad.indexOf('|') != -1 ? filtros.antiguedad.substr(filtros.antiguedad.indexOf('|')+1) : 1000)
          });

        if (filtros.superficie && filtros.superficie != "")
          this.options.filters.push({
            columna: 'superficie'
            ,operador: 'between'
            ,valor1: (filtros.superficie.indexOf('|') != -1 ? filtros.superficie.substr(0,filtros.superficie.indexOf('|')) : filtros.superficie)
            ,valor2: (filtros.superficie.indexOf('|') != -1 ? filtros.superficie.substr(filtros.superficie.indexOf('|')+1) : 999000)
          });

        this.cargar_garantias();
      }
      ,actualizar_pagina(pagina) {
        this.$log.info('======================================');
        this.$log.info('pagina', pagina);
        this.pagina = pagina;

        this.cargar_garantias();
      }
    },
    computed: {
      total_paginas() {
        let inicio = 1;
        let final = this.options.last_page;
        let espacios = 5;

        if (this.options.current_page > espacios)
          inicio = this.options.current_page - (espacios-1);

        let ult_pagina = this.options.current_page + (espacios-1);
        console.log('ult_pagina', ult_pagina);
        if (ult_pagina < final)
          final = ult_pagina;

        let paginas = [];

        console.log('inicio', inicio);
        console.log('final', final);
        for(let i=inicio; i<=final; i++) {
          paginas.push(i);
        }

        return paginas;
      }
    }
  };
</script>
