<template>
  <form @submit.prevent="enviar_mensaje">
    <div class="form-group">
      <label for="name">Nombre completo:</label>
      <input v-model="payload.nombre" type="text" class="form-control" id="name" placeholder="Tu nombre y apellidos">
    </div>
    <div class="form-group">
      <label for="email">Correo electrónico:</label>
      <input v-model="payload.email" type="email" class="form-control" id="email" placeholder="nombre@ejemplo.com">
    </div>
    <div class="form-group">
      <label for="phone">Teléfono:</label>
      <input v-model="payload.telefono" type="number" class="form-control" id="phone" placeholder="550055005" @keydown.space.prevent>
    </div>
    <div class="form-group">
      <label for="message">Déjanos un mensaje:</label>
      <textarea v-model="payload.mensaje" class="form-control" id="message" rows="3" placeholder="Hola, me interesa que me contacten."></textarea>
    </div>
    <div class="custom-control custom-checkbox my-1 mr-sm-2">
      <input v-model="payload.acepto_aviso" type="checkbox" class="custom-control-input" id="customControlInline">
      <label class="custom-control-label" for="customControlInline">
        <router-link :to="{name:'privacy'}" class="label__a text-small">He leído y acepto el Aviso de Privacidad</router-link>
      </label>
    </div>
    <div class="send-form">
      <button type="submit" class="btn btn-pink mt-4" :disabled="btnDisabled">Enviar información</button>
    </div>
  </form>
</template>

<script type="text/javascript">
  export default {
    props: {
      btnDisabled: {
        type: Boolean
        ,default: false
      }
      ,formClear: {
        type: Boolean
        ,default: false
      }
    }
    ,data: function() {
      return {
        payload: {
          nombre: null
          ,email: null
          ,telefono: null
          ,mensaje: null
          ,acepto_aviso: false
        }
      }
    }
    ,methods: {
      enviar_mensaje: function() {
        this.$emit('payload', this.payload);
      }
    }
    ,watch: {
      formClear: function(val) {
        if (val) {
          this.payload = {
            nombre: null
            ,email: null
            ,telefono: null
            ,mensaje: null
            ,acepto_aviso: false
          }
        }
      },
    }
  }
</script>