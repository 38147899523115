<template>
  <div class="container">
    <h1>Invierte de forma segura</h1>
    <div class="row">
      <div class="input-group">
        <select v-model="tipo" class="custom-select" id="property" aria-label="Example select with button addon">
          <option value="0" selected>Tipo de Propiedad</option>
          <option v-for="opcion in catalogos.tipo_propiedades" :key="opcion.id" :value="opcion.valor">{{ opcion.nombre }}</option>
        </select>
        <select v-model="estado" class="custom-select" id="location" aria-label="Example select with button addon">
          <option value="0" selected>Ubicación</option>
          <option v-for="opcion in catalogos.estados_validos" :key="opcion.id" :value="opcion.valor">{{ opcion.nombre }}</option>
        </select>
        <div class="input-group-append">
          <button @click="buscar" class="btn btn-pink" type="button">
            <span class="font-weight-bolder">
              Buscar
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  import api from '@/api/portal'

  export default {
    data: function() {
      return {
        catalogos: []
        ,tipo: 0
        ,estado: 0
      }
    }
    ,mounted: async function() {
      try{
        this.catalogos = (await api.obtener_catalogos()).data;
        this.$store.commit('setEstados', this.catalogos.estados_validos);
        this.$store.commit('setTiposGarantias', this.catalogos.tipo_propiedades);
        this.$store.commit('setAmenidades', this.catalogos.amenidades);
      }catch(e) {
        this.$log.info('error',e);
      }
    }
    ,methods: {
      buscar: function() {
        this.$store.commit('setBusqueda',{estado: this.estado, tipo: this.tipo});
        this.$log.info('buscar');

        this.$router.push({name: 'ListOfProperties'});
      }
    }
  }
</script>