<template>
  <div id="app">
    <router-view />
    <notifications group="alert" position="top right"/>
    <notifications group="notifications" position="top center"/>
    <div class="loading"></div>

    <div id="button-whatsaap">
      <ButtonWhatSapp
          text="¡Hola!\nMe encantaría conocer un poco más sobre ustedes. ¿Pueden darme más información?."
          :phone="phone" />
    </div>
  </div>
</template>
<script>
import ButtonWhatSapp from "@/components/ButtonWhatSapp.vue";
export default {
  name:'app',
  components: { ButtonWhatSapp },
  data() {
    return {
      phone: '5545329683'
    }
  },
}
</script>

<style lang="scss">
  html, body, #app {
    // width: 100vw;
    // height: 100vh;
    margin: 0px !important;
    padding: 0px;
  }

  #app {
    overflow-x: hidden;
    .clear {
      width: 100%;
      height: 100%;
    }

    .loading {
      width: 100vw;
      height: 100vh;
      background-color: rgba(76, 76, 76, .3);
      opacity: 1;
      transition: opacity linear 0.1s;
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9899;

      &::before {
        width: 40px;
        height: 40px;
        border: solid 3px #fff;
        border-bottom-color: #a112ac;
        border-radius: 50%;
        content: '';
        opacity: inherit;
        animation: 1s linear infinite spinner;
        will-change: transform;
        transform: translate3d(-50%, -50%);
        position: absolute;
        top: 50%;
        left: 50%;
      }
    }
  }

  @keyframes spinner {
    0% {
      transform: translate3d(-50%, -50%, 0) rotate(0deg);
    }
    100% {
      transform: translate3d(-50%, -50%, 0) rotate(360deg);
    }
  }
</style>