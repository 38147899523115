<template>
  <layout-default>
    <div class="search">
      <Search />
    </div>
    <div class="container our-goals">
      <!-- <p class="text-small">BpB Administradora</p> -->
      <h2 class="text-white text-center my-5">Queremos que encuentres tu próxima inversión</h2>
      <div class="card-deck">
        <div class="card card-transparent">
          <div class="card-image">
            <img src="../assets/icons/compromiso.png" class="card-img-top" alt="Compromiso">
          </div>
          <div class="card-body">
            <h5 class="card-title">Nuestro compromiso</h5>
            <p class="card-text">Reunimos a tu alcance propiedades estratégicamente ubicadas, garantizando el compromiso,
              el valor, la solidez y seguridad financiera.</p>
          </div>
        </div>
        <div class="card card-transparent">
          <div class="card-image">
            <img src="../assets/icons/seguridad.png" class="card-img-top" alt="Seguridad">
          </div>
          <div class="card-body">
            <h5 class="card-title">Seguridad</h5>
            <p class="card-text">Estamos comprometidos con la seguridad personal, familiar y de los bienes inmuebles de
              nuestros clientes en las propiedades que comercializamos.</p>
          </div>
        </div>
        <div class="card card-transparent">
          <div class="card-image">
            <img src="../assets/icons/confianza.png" class="card-img-top" alt="Confianza">
          </div>
          <div class="card-body">
            <h5 class="card-title">Confianza</h5>
            <p class="card-text">Te asesoramos con información real, legal, concreta y precisa para que elijas la opción
              que mejor se adecua a tu estilo de vida.</p>
          </div>
        </div>
      </div>
    </div>
  </layout-default>
</template>
<script>
import LayoutDefault from "@/layout/LayoutDefault";
import Search from "@/components/Search";
export default {
  name: 'Home',
  components: {
    Search,
    LayoutDefault,
  }
}
</script>