<template>
  <div class="search-filter__form text-small font-weight-bold">
    <form>
      <div class="form-group">
        <label>Ubicación</label>
        <select v-model="filtros.ubicacion" class="form-control">
          <option value="cualquiera">Cualquiera</option>
          <option v-for="estado in estados" :key="estado.id" :value="estado.valor">{{ estado.nombre }}</option>

        </select>
      </div>
      <div class="form-group">
        <label>Tipo de inmueble</label>
        <select v-model="filtros.tipo_inmueble" class="form-control">
          <option value="cualquiera">Cualquiera</option>
          <option v-for="garantia in tipo_garantias" :key="garantia.id" :value="garantia.valor">{{ garantia.nombre }}</option>
        </select>
      </div>
      <div class="form-group">
        <label>Precio</label>
        <select v-model="filtros.precio" class="form-control">
          <option value="">Cualquiera</option>
          <option value="300000|600000">$300,000.00 - $600,000.00</option>
          <option value="601000|999000">$601,000.00 - $999,000.00</option>
          <option value="1000000|3000000">$1,000,000.00 - $3,000,000.00</option>
          <option value="3000000|6000000">$3,000,000.00 - $6,000,000.00</option>
          <option value="6000000|1000000000000000">$6,000,000.00 en adelante</option>
        </select>
      </div>
      <div class="form-group">
        <label>Número de recámaras</label>
        <select v-model="filtros.numero_recamaras" class="form-control">
          <option value="">Cualquiera</option>
          <option value="1|2">1 a 2</option>
          <option value="3|4">3 a 4</option>
          <option value="5">5 o más</option>
        </select>
      </div>
      <div class="form-group">
        <label>Número de baños</label>
        <select v-model="filtros.numero_banios" class="form-control">
          <option value="">Cualquiera</option>
          <option value="1|2">1 a 2</option>
          <option value="3|4">3 a 4</option>
          <option value="5">5 o más</option>
        </select>
      </div>
      <div class="form-group">
        <label>Número de estacionamientos</label>
        <select v-model="filtros.numero_estacionamientos" class="form-control">
          <option value="">Cualquiera</option>
          <option value="1|2">1 a 2</option>
          <option value="3|4">3 a 4</option>
          <option value="5">5 o más</option>
        </select>
      </div>
      <div class="form-group">
        <label>Antigüedad en años</label>
        <select v-model="filtros.antiguedad" class="form-control">
          <option value="">Cualquiera</option>
          <option value="1|9">1 a 9 años</option>
          <option value="10|15">10 a 15 años</option>
          <option value="16">16 años o más</option>
        </select>
      </div>
      <div class="form-group">
        <label>Superficie en m2</label>
        <select v-model="filtros.superficie" class="form-control">
          <option value="">Cualquiera</option>
          <option value="40|89">40 a 89 m2</option>
          <option value="90|200">90 a 200m2</option>
          <option value="201|800">201 a 800m2</option>
          <option value="801">801m2 a más</option>
        </select>
      </div>
    </form>
  </div>
</template>

<script type="text/javascript">
  export default {
    data: function() {
      return {
        estados:[]
        ,tipo_garantias:[]
        ,filtros: {
          ubicacion: 'cualquiera'
          ,tipo_inmueble: 'cualquiera'
          ,precio: ""
          ,numero_recamaras: ""
          ,numero_banios: ""
          ,numero_estacionamientos: ""
          ,antiguedad: ""
          ,superficie: ""
        }
      }
    }
    ,mounted: function() {
      this.estados = this.$store.getters.getEstados;
      this.filtros.ubicacion = this.$store.getters.getBusqueda.estado;
      this.filtros.tipo_inmueble = this.$store.getters.getBusqueda.tipo;
      this.tipo_garantias = this.$store.getters.getTipoGarantias;
    }
    ,watch: {
      filtros: {
        handler: function(val) {
          this.$emit('filtros',val);
        }
        ,deep: true
      }
    }
  }
</script>