const api_key = 'AIzaSyATe84aehuIKi6vzgFsy4skCK_teNHx2o4';

let initializedGMaps = document.getElementById('googleMapsApi');

let resolveInitGMapsPromise, rejectInitGMapsPromise;

const initPromiseGMaps = new Promise((resolve, reject) => {
	resolveInitGMapsPromise = resolve;
	rejectInitGMapsPromise = reject;
});

export default function init() {
	if (initializedGMaps)
		return initPromiseGMaps;

	initializedGMaps = true;

	window['googleMapsInit'] = () => resolveInitGMapsPromise(window.google);

	let scriptMaps = document.createElement('script');
	scriptMaps.asunc = true;
	scriptMaps.defer = true;
    scriptMaps.src = `https://maps.googleapis.com/maps/api/js?key=${api_key}&callback=googleMapsInit`;
    scriptMaps.id = 'googleMapsApi'
    scriptMaps.onerror = rejectInitGMapsPromise;
    document.body.appendChild(scriptMaps);

    return initPromiseGMaps;
}