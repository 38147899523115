<template>
  <nav class="navbar navbar-expand-lg navbar-light">
    <router-link :to="{ name: 'home' }" class="navbar-brand">
      <img src="../assets/logo-inmuebles.png" alt="Administradora bpb" width="100px" />
    </router-link>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText"
      aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon text-white"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarText">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
          <router-link :to="{ name: 'home' }" class="nav-link text-white">Home</router-link>
        </li>
        <li class="nav-item active">
          <router-link :to="{ name: 'contact' }" class="nav-link text-white">Contacto</router-link>
        </li>
        <li class="nav-item active">
          <a :href="url" target="_blank" class="nav-link text-white">Blog</a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      url: 'https://blog.inmueblesbien.com.mx'
    }
  }
}

</script>
<style lang="scss"  scoped>
a {
  cursor: pointer;
}
</style>