import Vue from 'vue'
// import store from '@/store'
import axios from '@/plugins/axios'

const http = axios(process.env.VUE_APP_PORTAL_URL);

class Portal
{
	obtener_catalogos() {
		return http.get('/portal/otros/catalogos');
	}

	obtener_garantias(tipo, estado, opciones) {
		return http.get(Vue.prototype.$helper.url_options(`/portal/garantias/${tipo}/${estado}`, opciones));
	}

	obtener_garantia(id) {
		return http.get(`/portal/garantias/${id}`);
	}

	mostrar_imagen(id, imagen) {
		return http.get(`/portal/garantia/${id}/imagen/${imagen}`);
	}

	mensaje(payload) {
		return http.post(`/portal/mensaje`,payload);
	}
}

export default new Portal();