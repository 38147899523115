import Vue from "vue";
import utils from "@/helper/funciones";

const helper = {
  install(Vue) {
    Vue.prototype.$helper = {
      ...utils
    }
  }
}

Vue.use(helper);