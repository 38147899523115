import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from 'vuex-persist'

Vue.use(Vuex);

const vuexSessionStorage = new VuexPersist({
    key: 'portal.api.v1',
    storage: window.localStorage
});

export default new Vuex.Store({
  state: {
    status: 1
  },
  mutations: {
    setEstados: function(state, value) {
      state.estados = value;
    }
    ,setTiposGarantias: function(state, value) {
      state.tipos_garantias = value;
    }
    ,setBusqueda: function(state, busqueda) {
      state.busqueda = busqueda;
    }
    ,setGarantia: function(state, garantia) {
      state.garantia = garantia;
    }
    ,setListadoGarantias: function(state, listado) {
      state.listado = listado;
    }
    ,setAmenidades:function(state, value){
      state.amenidades = value;
    }
  },
  getters: {
    getEstados: function(state) {
      return state.estados;
    }
    ,getTipoGarantias: function(state) {
      return state.tipos_garantias;
    }
    ,getBusqueda: function(state) {
      return state.busqueda;
    }
    ,getGarantia: function(state) {
      return state.garantia;
    }
    ,getListadoGarantias: function(state) {
      return state.listado;
    }
    ,getAmenidades: function(state){
      return state.amenidades;
    }
  },
  actions: {},
  modules: {},
  plugins: [vuexSessionStorage.plugin]
});
