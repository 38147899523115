<template>
  <div class="property">
    <div class="card mb-3">
      <div class="row no-gutters">
        <div class="col-md-5">
          <img :src="imagen" alt="Propiedad" width="100%">
        </div>
        <div class="col-md-7">
          <div class="card-body">
            <h5 class="card-title">{{ garantia.titulo_nombre}}</h5>
            <h3 class="card-title font-weight-bold">${{ $helper.moneyFormat(garantia.precio) }}</h3>
              <div class="row">
                <div class="col-sm-4" v-for="amenidad in nombres_amenidades" :key="amenidad.id"><small> {{ amenidad.amenidad }}</small>  </div>
              </div>
            <small class="text-white">{{ direccion_compuesta }}</small> <br />
            <div class="d-flex justify-content-end mt-3">
              <button class="btn btn-pink" type="button" @click="seleccionar_garantia">Mostrar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
  // import api from '@/api/portal'

  export default {
    props: {
      garantia: {
        type: Object
        ,default: () => ({
          titulo_nombre: null
          ,calle: null
          ,no_interior: null
          ,no_exterior: null
          ,colonia: null
          ,delegacion_municipio: null
          ,estado: null
          ,cp: null
          ,valor: 0
          ,amenidades: []
          ,imagenes: []
        })
      }
    }
    ,data: function() {
      return {
        // imagen: null
        estados: [],
        amenidades:null,
      }
    }
    ,mounted: async function() {
      // this.$log.info('garantia', this.garantia);

      this.estados = this.$store.getters.getEstados;
      
      // try {
      //   let res = (await api.mostrar_imagen(this.garantia.id, this.garantia.imagenes[0].id)).data
      //   this.imagen = res;
      // }catch(e) {
      //   this.$log.info('err', e);
      // }
            
    }
    ,methods: {
      seleccionar_garantia: function() {
        this.$router.push({name: 'PropertyDetails',params:{id:this.garantia.id}});
      }
      ,formatear_amenidades(){
        this.amenidades = this.$store.getters.getAmenidades;

        let amenidades_format = [];
        this.amenidades.forEach(amenidad=>{
            if (this.garantia.amenidades.some(new_amenidad => new_amenidad.amenidad === amenidad.valor))
                amenidades_format.push({id:amenidad.id, amenidad: amenidad.nombre});
        });            
        return this.garantia.amenidades = amenidades_format;              
      }
    }
    ,computed: {
      direccion_compuesta: function() {
        let direccion = this.garantia.calle;

        if (this.garantia.no_interior)
          direccion += " "+this.garantia.no_interior;

        if (this.garantia.no_exterior)
          direccion += " #"+this.garantia.no_exterior;

        if (this.garantia.colonia)
          direccion += ", "+this.garantia.colonia;

        if (this.garantia.delegacion_municipio)
          direccion += ", "+this.garantia.delegacion_municipio;

        if (this.garantia.estado)
          direccion += ", "+this.estado;

        if (this.garantia.cp)
          direccion += ", CP "+this.garantia.cp;

        return direccion;
      }
      ,estado: function() {
        let estado = "";

        this.estados.forEach(tmp => {
          if (tmp.valor == this.garantia.estado)
            estado = tmp.nombre;
        });

        return estado;
      }
      ,imagen: function() {
        if (!this.garantia.imagenes || this.garantia.imagenes.length == 0)
          return '';
          
        return process.env.VUE_APP_PORTAL_URL+'/portal/garantia/'+this.garantia.id+'/imagen/'+this.garantia.imagenes[0].id;
      }
      ,nombres_amenidades(){
        return this.formatear_amenidades();
      }
    },
  }
</script>

<style lang="scss">
  .imagen_preview {
    height: 250px;
    text-align: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
</style>